import ReactDOM from "react-dom";

import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  // <React.StrictMode>
  // </React.StrictMode>,
  <App />,
  document.querySelector("#root")
);
